/**
 * Components/Service/Tags
 * @package Components
 */
import React, { useState } from 'react';
import WebpackerReact from 'webpacker-react';

/**
 * @param props None
 * @returns
 */
const Tags = (props) => {
  const tags = JSON.parse(props.tags).slice(props.firstViewCount);

  const [showFlg, setShowFlg] = useState(false);

  /**
   * もっと見るをクリック
   */
  const show = () => {
    setShowFlg(true);
  };

  if (!tags.length) return null;

  if (tags.length && !showFlg) {
    return (
      <a className='p-article_tagMore' onClick={show}>
        ＋ もっと見る
      </a>
    );
  }

  if (tags.length && showFlg) {
    return (
      <ul className='p-article_tagList'>
        {tags.map((tag, i) => {
          return (
            <li key={i} className='p-article_tagItem'>
              <a className='p-article_tagButton' href={`/${tag.category.alias}/${tag.alias}`}>
                <svg className='p-article_tagIcon'>
                  <use xlinkHref='#svgs-icon_tag'></use>
                </svg>
                {tag.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  }
};

export default WebpackerReact.setup({ Tags });
