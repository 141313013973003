(() => {
  window.addEventListener('load', () => {
    const target = document.getElementById('js-modalTarget');
    const modal = document.getElementById('js-modal');
    const close = document.getElementById('js-modalClose');
    if (!target || !modal || !close) return false;

    target.addEventListener('click', () => {
      if (modal.dataset.modal == 'false') modal.dataset.modal = 'true';
    });

    close.addEventListener('click', () => {
      if (modal.dataset.modal == 'true') modal.dataset.modal = 'false';
    });
  });
})();
