import $ from 'jquery';
(() => {
  window.addEventListener('load', () => {
    let body = document.body;
    let trigger = $('#js-globalNaviOpen');

    trigger.on('click', function () {
      document.body.dataset.menuOpen = document.body.dataset.menuOpen == 'true' ? 'false' : 'true';
    });
  });
})();
