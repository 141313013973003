/**
 * Components/Service/Movie
 * @package Components
 */
import React from 'react';
import WebpackerReact from 'webpacker-react';

// @see https://github.com/troybetz/react-youtube
import YouTube from 'react-youtube';

/**
 * @param props
 * @returns
 */
const Movie = (props) => {
  return <YouTube videoId={props.videoId} opts={props.opts} />;
};

Movie.defaultProps = {
  opts: {
    playerVars: {
      autoplay: 0,
      controls: 0,
      rel: 0,
      showinfo: 0,
      autohide: 1,
    },
  },
};

export default WebpackerReact.setup({ Movie });
